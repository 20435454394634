import React from "react";

import Collapsible from "react-collapsible";
import Countdown, { zeroPad } from "react-countdown";

import PortalModal from "./modal/PortalModal";

import Button from "./Button";
import Border from "./Border";

import LogoEditor from "./LogoEditor";
import CookieBanner from './CookieBanner'

import Img, { getImageUrl, Sizes } from "../components/blocks/editable/Img";

import LinkSelect from "./LinkSelect";

import ColorPicker from "./ColorPicker";

import Backend from "../utils/Backend";
import General from "../utils/General";
import Currency from "../utils/Currency";
import Cart from "../utils/Cart";
import Notify from "../utils/Notify";

import SmartList from "./common/SmartList";

import $ from "jquery";

export default class Menu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      canRenderContent: props.canRenderContent,
      editable: props.editable,
      showModal: false,
      website: props.website,
      data: props.menu,
      cart: props.cart,
    };

    this._updateMenu = General.debounce(
      () => {
        Backend.updateMenu(this.state.data);
      },
      1000,
      false
    );
  }

  componentDidMount() {
    this._setup();
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  updateWebsite(website) {
    let { data } = this.state;
    data.website = website;
    this.setState({ data });
  }

  _updateBanner(){
    let { data, website } = this.state;

    let websiteData = {...website}
    websiteData.hide_approval_status_banner = true
    Backend.updateWebsite(websiteData)
    .then(() => {
      this.setState({
        website: websiteData
      })
    }).catch(e => Notify.error(e.message))
  }

  updateCart(cart) {
    this.setState({ cart });
  }

  updateMenu(data, updateApi = true) {
    this.setState({ data }, () => {
      if (updateApi) {
        this._updateMenu();
      }
    });
  }

  _update(data, callback) {
    this.setState({ data }, () => {
      if (callback) {
        callback();
      }
      this._updateMenu();
    });
  }

  showCartDropdown() {
    $(".d-header .co-bag .co-bag-dropdown").removeClass("hide");
    setTimeout(function () {
      $(".d-header .co-bag").addClass("show-bag-dropdown");
    }, 10);
  }

  hideCartDropdown() {
    $(".d-header .co-bag").removeClass("show-bag-dropdown");
    setTimeout(function () {
      $(".d-header .co-bag .co-bag-dropdown").addClass("hide");
    }, 600);
  }

  _setup() {
    let { data, website } = this.state;

    this.setState({
      data,
      loading: false,
    });
  }

  _addMenuItem(data) {
    data.nav_items.push({
      text: "New Menu Link Text",
      url: "#",
      nav_items: [],
      open_in_new_tab: false,
    });

    this._update(data);
  }

  _renderModalMenuItems(data) {
    return (
      <SmartList
        title="Menu Links"
        canDelete={false}
        items={data.nav_items}
        itemName="Menu Link"
        value={(navItem) => navItem.text}
        renderItem={(navItem, index) => {
          return (
            <Collapsible
              trigger={navItem.text}
              className="mb--1"
              overflowWhenOpen="visible"
              triggerSibling={() => {
                return (
                  <a
                    className="delete-item delete-item-collapsible"
                    style={{ right: 30 }}
                    onClick={() => {
                      data.nav_items.splice(index, 1);
                      this._update(data);
                    }}
                  />
                );
              }}
            >
              <div className="boxed boxed--sm overflow-visible">
                <span>Title</span>
                <input
                  type="text"
                  className="mb--05"
                  defaultValue={navItem.text}
                  onChange={(event) => {
                    let text = event.target.value;
                    navItem.text = text;
                    data.nav_items[index] = navItem;
                    this._update(data);
                  }}
                />

                <span>Link</span>
                <LinkSelect
                  url={navItem.url}
                  onUpdated={(url) => {
                    navItem.url = url;
                    data.nav_items[index] = navItem;
                    this._update(data);
                  }}
                />

                {!navItem.url?.startsWith("/#") && (
                  <>
                    <span>Open In New Tab</span>
                    <select
                      className="mb-3"
                      value={navItem.open_in_new_tab}
                      onChange={(event) => {
                        navItem.open_in_new_tab = event.target.value === "true";

                        data.nav_items[index] = navItem;

                        this._update(data);
                      }}
                    >
                      <option value="true">Yes</option>
                      <option value="false">No</option>
                    </select>
                  </>
                )}
                <Collapsible
                  trigger={`Show ${navItem.text} Dropdown Links`}
                  triggerWhenOpen={`Close ${navItem.text} Dropdown Links`}
                >
                  <Border className="mt--1">
                    {navItem.nav_items.map((childNavItem, childIndex) => {
                      return (
                        <Collapsible
                          key={childNavItem.id || childIndex.uid}
                          trigger={childNavItem.text}
                          className="mb--1"
                          overflowWhenOpen="visible"
                          triggerSibling={() => {
                            return (
                              <a
                                className="delete-item delete-item-collapsible"
                                style={{ right: 40 }}
                                onClick={() => {
                                  navItem.nav_items.splice(childIndex, 1);
                                  data.nav_items[index] = navItem;
                                  this._update(data);
                                }}
                              />
                            );
                          }}
                        >
                          <br />
                          <span>Title</span>
                          <input
                            type="text"
                            defaultValue={childNavItem.text}
                            onChange={(event) => {
                              let text = event.target.value;
                              childNavItem.text = text;
                              navItem.nav_items[childIndex] = childNavItem;

                              data.nav_items[index] = navItem;
                              this._update(data);
                            }}
                          />
                          <br />
                          <br />
                          <span>Link</span>
                          <LinkSelect
                            url={childNavItem.url}
                            onUpdated={(url) => {
                              childNavItem.url = url;

                              navItem.nav_items[childIndex] = childNavItem;

                              data.nav_items[index] = navItem;

                              this._update(data);
                            }}
                          />

                          {!childNavItem.url?.startsWith("/#") && (
                            <>
                              <span>Open In New Tab</span>
                              <select
                                className="mb-3"
                                value={childNavItem.open_in_new_tab}
                                onChange={(event) => {
                                  childNavItem.open_in_new_tab =
                                    event.target.value === "true";

                                  navItem.nav_items[childIndex] = childNavItem;

                                  data.nav_items[index] = navItem;

                                  this._update(data);
                                }}
                              >
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                              </select>
                            </>
                          )}
                        </Collapsible>
                      );
                    })}

                    <div className="row">
                      <div className="col-md-12">
                        <a
                          className="btn"
                          onClick={() => {
                            navItem.nav_items.push({
                              text: "New Dropdown Link",
                              url: "#",
                              uid: General.uuid(),
                              open_in_new_tab: false,
                            });
                            data.nav_items[index] = navItem;
                            this._update(data);
                          }}
                        >
                          <span className="btn__text">
                            Add {navItem.text} Dropdown Link
                          </span>
                        </a>
                      </div>
                    </div>
                  </Border>
                </Collapsible>
              </div>
            </Collapsible>
          );
        }}
        onUpdated={(navItems) => {
          data.nav_items = navItems;
          this._update(data);
        }}
      />
    );
  }

  _renderModal() {
    let { data, showModal } = this.state;

    return (
      <PortalModal
        show={showModal}
        onHide={() => this.setState({ showModal: false })}
      >
        <h2>Menu</h2>

        <LogoEditor
          menu={data}
          onUpdated={(data) => {
            this.setState({ data });
            this._update(data);
          }}
        />

        <Border>
          <Collapsible trigger="Menu Links">
            <br />
            {this._renderModalMenuItems(data)}
            {this._renderModalAddMenuItemButton(data)}
          </Collapsible>
        </Border>

        <Border>
          <Collapsible trigger="Buttons">
            <br />
            {this._renderPrimaryButtonToggle(data)}
            {this._renderSecondaryButtonToggle(data)}
          </Collapsible>
        </Border>

        <Border>
          <Collapsible trigger="Colours">
            <br />
            <ColorPicker
              title={"Menu Background Color"}
              color={data.settings.quaternary_color}
              onColorSelected={(color) => {
                data.settings.quaternary_color = color;
                this._update(data);
              }}
            />

            <br />

            <ColorPicker
              title={"Menu Text Color"}
              color={data.settings.secondary_color}
              onColorSelected={(color) => {
                data.settings.secondary_color = color;
                this._update(data);
              }}
            />

            <br />

            <ColorPicker
              title={
                "Scrolling Menu Background Color (menu changes when you scroll)"
              }
              color={data.settings.tertiary_color}
              onColorSelected={(color) => {
                data.settings.tertiary_color = color;
                this._update(data);
              }}
            />

            <br />

            <ColorPicker
              title={"Scrolling Menu Text Color (menu changes when you scroll)"}
              color={data.settings.primary_color}
              onColorSelected={(color) => {
                data.settings.primary_color = color;
                this._update(data);
              }}
            />
          </Collapsible>
        </Border>
      </PortalModal>
    );
  }

  _renderModalAddMenuItemButton(data) {
    return (
      <div className="row">
        <div className="col-md-12">
          <a className="btn" onClick={() => this._addMenuItem(data)}>
            <span className="btn__text">Add Menu Link</span>
          </a>
        </div>
      </div>
    );
  }

  _renderPrimaryLogo(data, editable) {
    if (data.logo_custom == true) {
      return this._renderLogoCustom(data.primary_logo, "dark");
    }
    return this._renderLogo(data.primary_image, "dark", editable, (image) => {
      data.primary_image = image;
      this._update(data);
    });
  }

  _renderSecondaryLogo(data, editable) {
    if (data.logo_custom == true) {
      return this._renderLogoCustom(data.secondary_logo, "light");
    }
    return this._renderLogo(
      data.secondary_image,
      "light",
      editable,
      (image) => {
        data.secondary_image = image;
        this._update(data);
      }
    );
  }

  _renderLogo(image, theme, editable, onUpdated) {
    if (!image) {
      return null;
    }

    let className = "logo logo-" + theme;

    return (
      <Img
        className={className + " size-" + this.state.data.logo_size}
        img={image}
        size={Sizes.SMALL}
        alt={"logo"}
        editable={false}
        renderModal={(content) => this.props.renderModal(content)}
        onUpdated={(image) => {
          onUpdated(image);
        }}
      />
    );
  }

  _renderLogoCustom(logo, theme) {
    return (
      <div className={`logo logo-${theme}`}>
        {this._renderLogoCustomContent(logo, theme)}
      </div>
    );
  }

  _renderLogoCustomContent(logo, theme) {
    if (logo.style == "icon_top_text_bottom") {
      return this._renderLogoCustomIconTopTextBottom(logo);
    } else if (logo.style == "text_top_icon_bottom") {
      return this._renderLogoCustomTextTopIconBottom(logo);
    } else if (logo.style == "text_left_icon_right") {
      return this._renderLogoCustomTextLeftIconRight(logo);
    }

    return this._renderLogoCustomIconLeftTextRight(logo);
  }

  _renderLogoCustomIconTopTextBottom(logo) {
    return (
      <div className="d-flex flex-column p-icon-top">
        {this._renderLogoCustomIcon(logo)}
        {this._renderLogoCustomText(logo)}
      </div>
    );
  }

  _renderLogoCustomTextTopIconBottom(logo) {
    return (
      <div className="d-flex flex-column p-icon-btm">
        {this._renderLogoCustomText(logo)}
        {this._renderLogoCustomIcon(logo)}
      </div>
    );
  }

  _renderLogoCustomTextLeftIconRight(logo) {
    return (
      <div className="d-flex align-items-center p-icon-right">
        {this._renderLogoCustomText(logo)}
        {this._renderLogoCustomIcon(logo)}
      </div>
    );
  }

  _renderLogoCustomIconLeftTextRight(logo) {
    return (
      <div className="d-flex align-items-center p-icon-left">
        {this._renderLogoCustomIcon(logo)}
        {this._renderLogoCustomText(logo)}
      </div>
    );
  }

  _renderLogoCustomText(logo, className = "") {
    let { data } = this.state;
    return (
      <span
        className={className + " logo-text"}
        style={{
          fontFamily: logo.font.family_name,
          fontSize: parseInt(data.logo_size),
          fontWeight: parseInt(logo.font.weight),
          color: logo.text_color,
        }}
      >
        {logo.text}
      </span>
    );
  }

  _renderLogoCustomIcon(logo, className = "") {
    let { data } = this.state;
    return (
      <i
        className={`logo-icon icon icon--sm ${logo.icon}`}
        style={{
          color: logo.icon_color,
          fontSize: parseInt(data.logo_size) + parseInt(data.logo_size * 0.2),
        }}
      />
    );
  }

  _renderPrimaryButtonToggle(data) {
    return (
      <>
        <div class="row">
          <div class="col-md-8">
            <h4>Primary</h4>
          </div>
          <div class="col-md-4 text-right">
            <div class="input-checkbox input-checkbox--switch">
              <input
                id="menu__cb_pb"
                type="checkbox"
                defaultChecked={data.primary_button}
                onChange={(e) => {
                  if (e.target.checked) {
                    data.primary_button = DEFAULT_PRIMARY_BUTTON;
                  } else {
                    data.primary_button = null;
                  }
                  this._update(data);
                }}
              />
              <label for="menu__cb_pb"></label>
            </div>
          </div>
        </div>
      </>
    );
  }

  _renderSecondaryButtonToggle(data) {
    return (
      <>
        <div class="row">
          <div class="col-md-8">
            <h4>Secondary</h4>
          </div>
          <div class="col-md-4 text-right">
            <div class="input-checkbox input-checkbox--switch">
              <input
                id="menu__cb_sb"
                type="checkbox"
                defaultChecked={data.secondary_button}
                onChange={(e) => {
                  if (e.target.checked) {
                    data.secondary_button = DEFAULT_SECONDARY_BUTTON;
                  } else {
                    data.secondary_button = null;
                  }
                  this._update(data);
                }}
              />
              <label for="menu__cb_sb"></label>
            </div>
          </div>
        </div>
      </>
    );
  }

  _renderNavItems(navItems, hamburgerHeader) {
    if (!navItems) {
      return null;
    }

    return navItems.map((navItem) => {
      if (navItem.nav_items.length === 0) {
        return (
          <li>
            <a
              href={navItem.url}
              target={navItem.open_in_new_tab ? "_blank" : ""}
            >
              {navItem.text}
            </a>
          </li>
        );
      }
      return (
        <li
          className={`${
            hamburgerHeader
              ? "menu-item dropdown"
              : "menu-item dropdown dropdown--hover"
          }`}
        >
          <a
            href={navItem.url}
            target={navItem.open_in_new_tab ? "_blank" : ""}
            class="dropdown__trigger"
          >
            <span>{navItem.text}</span>
          </a>
          <div class="dropdown__container text-left">
            <div class="container">
              <div class="row">
                <div
                  className={`${
                    hamburgerHeader
                      ? "dropdown__content col-auto"
                      : "dropdown__content col-md-2 col-11"
                  }`}
                >
                  <ul class="menu-vertical">
                    {navItem.nav_items.map((childNavItem) => {
                      return (
                        <li class="menu-item text-truncate">
                          <a
                            href={childNavItem.url}
                            target={
                              childNavItem.open_in_new_tab ? "_blank" : ""
                            }
                            className={
                              childNavItem.url.includes("#")
                                ? "dropdown-inactive"
                                : ""
                            }
                          >
                            <span>{childNavItem.text}</span>
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </li>
      );
    });
  }

  _renderEditable(editable) {
    if (!editable) {
      return null;
    }

    return (
      <>
        <div className="nav-button-edit">
          <div className="button-slide">
            <a
              className="button"
              href="javascript:void(0)"
              onClick={() => {
                this.setState({ showModal: true });
              }}
            >
              <span class="slide-text">Edit Menu</span>
              <i class="material-icons m-icon-s1 c-white-opa-8">settings</i>
            </a>
          </div>
        </div>
        {this._renderModal()}
      </>
    );
  }

  _renderMenuContent(data, editable) {
    if (data.logo_position === "center") {
      return this._renderMenuContentStyleCenter(data, editable);
    }
    return this._renderMenuContentStyleDefault(data, editable);
  }

  _renderMenuContentStyleDefault(data, editable) {
    return (
      <>
        <div className="col-md-4 col-lg-3 hidden-xs">
          {this._renderMenuLogo(data, editable)}
        </div>
        <div className="col-md-8 col-lg-9 text-md-right">
          {this._renderMenuNavItems(data, editable)}
          {this._renderMenuButtons(data, editable)}
        </div>
        {this._renderEditable(editable)}
      </>
    );
  }

  _renderMenuContentStyleCenter(data, editable) {
    return (
      <>
        <div className="col-md-4 text-center text-left-sm hidden-xs order-md-2">
          {this._renderMenuLogo(data, editable)}
        </div>
        <div className="col-md-4 order-md-1 bar-module-margin-zero">
          {this._renderMenuNavItems(data, editable)}
        </div>
        <div className="col-md-4 text-right text-left-md order-md-3">
          {this._renderMenuButtons(data, editable)}
        </div>
        {this._renderEditable(editable)}
      </>
    );
  }

  _renderMenuNavItems(data, editable, hamburgerHeader) {
    return (
      <div className={`${hamburgerHeader ? "" : "bar__module"}`}>
        <ul className="menu-horizontal">
          {this._renderNavItems(data.nav_items, hamburgerHeader)}
        </ul>
      </div>
    );
  }

  _registerCheckoutButtonListener() {
    if (this.registeredCheckoutButtonListener) {
      return;
    }

    setTimeout(() => {
      $(".d-header .nav-checkout").hover(() => {
        this.showCartDropdown();
      });

      $(
        ".d-header .co-bag .head .close, .d-header .co-bag .co-bag-dropdown-bg"
      ).on("click", () => {
        this.hideCartDropdown();
      });

      this.registeredCheckoutButtonListener = true;
    }, 3000);
  }

  _renderCartDropdown(primaryButtonStyle) {
    let { cart } = this.state;

    let itemsNo = Cart.getTotalItemsNo(cart);
    let itemsText = `${Cart.getTotalItemsNo(cart)} item`;
    if (itemsNo > 1) {
      itemsText += "s";
    }
    return (
      <div class="main">
        <div class="head row m-0 align-items-center justify-content-between">
          <div className="title col-auto fs-18">
            <b>My Cart</b> ({itemsText})
          </div>
          <a href="javascript:void(0);" class="close">
            <span class="material-icons-two-tone">close</span>
          </a>
        </div>

        <div class="items">
          {cart.items.map((item) => {
            let product = item.product;

            let price = product.on_sale ? product.sale_price : product.price;
            if (item.variant && item.variant.options.length > 0) {
              price = item.variant.price;
            }
            price *= item.quantity;
            return (
              <div className="row m-0 item">
                <div
                  class="img m-0"
                  style={{
                    backgroundImage:
                      "url(" +
                      getImageUrl(item.product.featured_image, Sizes.SMALL) +
                      ")",
                  }}
                />

                <div className="col">
                  <div className="price">
                    {Currency.format(
                      price,
                      window.CURRENT_WEBSITE_SHOP_CURRENCY
                    )}
                  </div>
                  <div className="title">{item.product.name}</div>
                  {item.variant && (
                    <div className="variant">
                      {item.variant.name || item.variant.options.join(", ")}
                    </div>
                  )}
                  <div className="quantity">Quantity: {item.quantity}</div>
                  {item.release_at && (
                    <>
                      <div className="countdown">
                        Held For:
                        <Countdown
                          date={item.release_at}
                          key={item.product.id}
                          precision={3}
                          renderer={({ hours, minutes, seconds }) => {
                            if (hours > 0) {
                              return (
                                <span>
                                  {" " + zeroPad(hours)}h {zeroPad(minutes)}m{" "}
                                  {zeroPad(seconds)}s
                                </span>
                              );
                            } else if (minutes > 0) {
                              return (
                                <span>
                                  {" " + zeroPad(minutes)}m {zeroPad(seconds)}s
                                </span>
                              );
                            } else {
                              return <span>{" " + zeroPad(seconds)}s</span>;
                            }
                          }}
                          onComplete={() => {
                            if (item.cart_item_uuid) {
                              if (
                                UNRESERVER_CART_ITEM_UUIDS.indexOf(
                                  item.cart_item_uuid
                                ) > -1
                              ) {
                                return; // duplicate onComplete call
                              }
                              UNRESERVER_CART_ITEM_UUIDS.push(
                                item.cart_item_uuid
                              );
                            }

                            Cart.remove(
                              item.product,
                              item.variant,
                              item.quantity
                            )
                              .then((cart) => {
                                this.setState({ cart });
                                this.props.onUpdatedCart(cart);
                                Notify.error(
                                  `"${item.product.name}" removed from cart`
                                );
                              })
                              .catch((error) => {
                                Notify.error(error.message);
                              });
                          }}
                        />
                      </div>
                    </>
                  )}
                </div>

                <div class="col-auto p-0 delete">
                  <a
                    href="javascript:void(0);"
                    class="material-icons"
                    onClick={() => {
                      Cart.remove(item.product, item.variant, item.quantity)
                        .then((cart) => {
                          this.setState({ cart });
                          this.props.onUpdatedCart(cart);
                        })
                        .catch((error) => {
                          Notify.error(error.message);
                        });
                    }}
                  >
                    delete
                  </a>
                </div>
              </div>
            );
          })}
        </div>

        <div className="bottom">
          <a
            href="/shop/checkout"
            className="btn btn--primary type--uppercase btn--sm block"
            style={primaryButtonStyle}
          >
            <span class="btn__text">
              Checkout{" "}
              {Currency.format(
                cart.subtotal,
                window.CURRENT_WEBSITE_SHOP_CURRENCY
              )}
            </span>
          </a>

          <a
            className="btn btn--secondary type--uppercase btn--sm ml-0 block"
            onClick={() => this.hideCartDropdown()}
          >
            <span class="btn__text">Continue Shopping</span>
          </a>
        </div>
      </div>
    );
  }

  _renderCartButton() {
    let { cart, data } = this.state;

    if(window.HIDE_CART || cart == null) {
      return null;
    }

    let style = {};
    if (data.primary_button) {
      let settings = data.primary_button.settings;
      if (settings.primary_color) {
        style.color = settings.primary_color;
      }
      if (settings.secondary_color) {
        style.backgroundColor = settings.secondary_color;
      }
      if (settings.tertiary_color) {
        style.borderColor = settings.tertiary_color;
      }
    }

    this._registerCheckoutButtonListener();

    return (
      <>
        <div className="co-bag">
          <a href="/shop/checkout" className="nav-checkout">
            <span>{Cart.getTotalItemsNo(cart)}</span>

            <svg
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M24.375 9.14062H20.3906V8.67188C20.3906 5.69531 17.9766 3.28125 15 3.28125C12.0234 3.28125 9.60938 5.69531 9.60938 8.67188V9.14062H5.625C5.10645 9.14062 4.6875 9.55957 4.6875 10.0781V25.7812C4.6875 26.2998 5.10645 26.7188 5.625 26.7188H24.375C24.8936 26.7188 25.3125 26.2998 25.3125 25.7812V10.0781C25.3125 9.55957 24.8936 9.14062 24.375 9.14062ZM18.2812 9.14062H11.7188V8.67188C11.7188 6.8584 13.1865 5.39062 15 5.39062C16.8135 5.39062 18.2812 6.8584 18.2812 8.67188V9.14062Z"
                fill="#4a90e2"
              />
            </svg>
          </a>
          <div className="co-bag-dropdown hide">
            {this._renderCartDropdown(style)}
            <div className="co-bag-dropdown-bg"></div>
          </div>
        </div>
      </>
    );
  }

  _renderMenuButtons(data, editable, hamburgerHeader) {
    let primaryWebsiteColor = data.website.primary_color;
    if (data.primary_button && data.primary_button.settings.msc_enabled) {
      data.primary_button.settings.quinary_color = primaryWebsiteColor;
      data.primary_button.settings.senary_color = primaryWebsiteColor;
    }
    if (data.secondary_button && data.secondary_button.settings.msc_enabled) {
      data.secondary_button.settings.quaternary_color = primaryWebsiteColor;
      data.secondary_button.settings.senary_color = primaryWebsiteColor;
    }
    return (
      <div className={`${hamburgerHeader ? "" : "bar__module"}`}>
        <Button
          data={data.secondary_button}
          type="secondary"
          size="small"
          editable={editable}
          secondaryColorsTitle="Sticky Nav Bar Colors"
          secondaryColorsSubtitle="Displayed when on solid background colour (such as white)"
          onUpdated={(button) => {
            data.secondary_button = button;
            this._update(data);
          }}
        />
        <Button
          data={data.primary_button}
          type="primary"
          size="small"
          editable={editable}
          secondaryColorsTitle="Sticky Nav Bar Colors"
          secondaryColorsSubtitle="Displayed when on solid background colour (such as white)"
          onUpdated={(button) => {
            data.primary_button = button;
            this._update(data);
          }}
        />
        {!hamburgerHeader && (
          <span className="hidden-xs">{this._renderCartButton()}</span>
        )}
        {data.primary_button && (
          <style
            dangerouslySetInnerHTML={{
              __html: `
            .s-header .h-bar.pos-fixed .d-header .btn--primary,
            .cookie-banner .btn--primary{
              background-color: ${data.primary_button.settings.quinary_color} !important;;
              border-color: ${data.primary_button.settings.senary_color} !important;;
            }
            .s-header .h-bar.pos-fixed .d-header .btn--primary > span {
              color: ${data.primary_button.settings.quaternary_color} !important;
            }
          `,
            }}
          />
        )}
        {data.secondary_button && (
          <style
            dangerouslySetInnerHTML={{
              __html: `
            .s-header .h-bar.pos-fixed .d-header .btn--secondary,
            .cookie-banner .btn--secondary{
              background-color: ${data.secondary_button.settings.quinary_color} !important;;
              border-color: ${data.secondary_button.settings.senary_color} !important;;
            }
            .s-header .h-bar.pos-fixed .d-header .btn--secondary > span,
            .cookie-banner .btn--secondary{
              color: ${data.secondary_button.settings.quaternary_color} !important;;
            }
            @media (max-width: 767px){
              #menu1 .btn--secondary {
                background-color: ${data.secondary_button.settings.quinary_color} !important;;
                border-color: ${data.secondary_button.settings.senary_color} !important;;
              }
              #menu1 .btn--secondary > span {
                color: ${data.secondary_button.settings.quaternary_color} !important;;
              }
            }
          `,
            }}
          />
        )}
      </div>
    );
  }

  _renderMenuLogo(data, editable, hamburgerHeader) {
    let logoCenterInColumn;
    if (data.logo_position === "center" && !hamburgerHeader) {
      logoCenterInColumn = true;
    }
    return (
      <div className="bar__module s-logos">
        <a
          className={`logo-link ${logoCenterInColumn ? "mx-auto" : ""}`}
          href="/"
        >
          {this._renderPrimaryLogo(data, editable)}
          {this._renderSecondaryLogo(data, editable)}
        </a>
      </div>
    );
  }

  _renderSiteWideNotice() {
    let { data, editable } = this.state;

    let merchantText = "In Development"
    let visitorText = "Coming Soon"

    if (!data || data.website.application_status === "approved" && data.website.hide_approval_status_banner) {
      return null;
    }

    if(data?.website.application_status === "pending"){
      merchantText = "Pending Approval"
    }

    if(data?.website.application_status === "approved"){
      merchantText = <p>Your website is now live and able to accept payments</p>
    }

    if(data?.website.application_status === "rejected"){
      merchantText = <p>Your application has been declined and your website will be closed - For more information see <b>FAQs</b></p>
    }

    if(data?.website.application_status === "approved" && data?.website.status === "draft"){
      merchantText = "Maintenance Mode"
    }

    let text = editable ? merchantText : visitorText

    let approvalNeeded = data.website.application_status === "draft"
    return (
      <div
        className="main-container site-wide-notice">
        <div class="container">
          <div
            class="row align-items-center justify-content-center flex-nowrap"
          >
            <div class="col col-md-auto">
              <p>{text}</p>
            </div>
            {
              (editable && approvalNeeded) &&
              <div className="col-auto">
                <button
                  className="btn btn--white"
                  onClick={() => this.props.onApprovalRequestClicked()}
                >
                  Submit For Approval
                </button>
              </div>
            }
            {
              (editable && data?.website.application_status === "rejected") &&
              <div className="col-auto">
                <button
                  className="btn btn--white"
                  onClick={() => window.open("https://website-builder.ie")}
                >
                  Read FAQs
                </button>
              </div>
            }
            {
              (editable && data?.website.application_status === "approved") &&
              <div className="col-auto">
                <button
                  className="btn btn--white"
                  onClick={() => {
                    this._updateBanner()
                  }}
                >
                  Close
                </button>
              </div>
            }
          </div>
        </div>
      </div>
    );
  }

  _renderMenu() {
    let { cart, data, editable, website } = this.state;

    let hamburgerHeader = data.style === "hamburger";

    // Show Hamburger Icon/Button only when the Nav Items, or Primary Button or Secondary Button avaialble
    let showHamburgerMenu =
      data.nav_items.length > 0 || data.primary_button || data.secondary_button;

    return (
      <>
        {General._renderMenuStyle(data.settings)}

        {!website.hide_menu && !this.props.hideMenu && (
          <>
            {General.updateScroll()}
            {General.updateToggleClass()}

            {/* starts : Hamburger Header */}
            {hamburgerHeader && (
              <>
                <nav class="bar bar-toggle bar--transparent bg--dark h-hamburger">
                  <div class="container">
                    <div class="row align-items-center">
                      <div class="col-6">
                        {this._renderMenuLogo(data, editable, hamburgerHeader)}
                      </div>

                      {showHamburgerMenu && (
                        <div class="col-6 d-flex justify-content-end">
                          <div class="bar__module">
                            <div class="modal-instance pull-right">
                              <a class="modal-trigger menu-toggle" href="#">
                                <i class="stack-interface stack-menu"></i>
                              </a>

                              <div class="modal-container menu-fullscreen">
                                <div
                                  class="modal-content"
                                  data-width="100%"
                                  data-height="100%"
                                >
                                  <div class="d-flex flex-column justify-content-center mh-100p text-center">
                                    <div className="nav-block">
                                      {this._renderMenuNavItems(
                                        data,
                                        editable,
                                        hamburgerHeader
                                      )}
                                    </div>
                                    <div>
                                      {this._renderMenuButtons(
                                        data,
                                        editable,
                                        hamburgerHeader
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {this._renderEditable(editable)}
                    </div>
                  </div>
                </nav>
              </>
            )}
            {/* end : Hamburger Header */}

            {/* starts : Mobile Header */}
            {!hamburgerHeader && (
              <div className="m-header bar bar--sm visible-xs bar--mobile-sticky">
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-8 s-logos">
                      <a className="logo-link" href="/">
                        {this._renderPrimaryLogo(data, editable)}
                        {this._renderSecondaryLogo(data, editable)}
                      </a>
                    </div>

                    <div className="col-4">
                      <div className="row align-items-center justify-content-end">
                        {cart && (
                          <div className="pl-0 col-auto">
                            {this._renderCartButton()}
                          </div>
                        )}

                        {showHamburgerMenu && (
                          <div className="pl-0 col-auto">
                            <a
                              href="/"
                              className="hamburger-toggle"
                              data-toggle-class="#menu1;hidden-xs hidden-sm"
                            >
                              <span></span>
                              <span></span>
                              <span></span>
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* end : Mobile Header */}

            {/* starts : Desktop Header */}
            {!hamburgerHeader && (
              <nav
                id="menu1"
                className={`d-header bar bar-1 hidden-xs bar--transparent`}
              >
                <div className="container">
                  <div className="row align-items-center">
                    {this._renderMenuContent(data, editable)}
                  </div>
                </div>
              </nav>
            )}
            {/* end : Desktop Header */}
          </>
        )}
      </>
    );
  }

  _renderChat() {
    let { cart, data, editable } = this.state;

    if (editable) {
      return null;
    }

    const Chat = require("./Chat.js").default;
    return <Chat website={data.website} editable={editable} />;
  }

  _renderPaymentWidget() {
    let { cart, data, editable } = this.state;

    const PaymentWidget = require("./PaymentWidget.js").default;
    return <PaymentWidget website={data.website} editable={editable} />;
  }

  render() {
    let { cart, data, editable, website } = this.state;

    let navBarParentClassName = this.props.hidePrimaryNavBar
      ? "secondary-nav-bar"
      : "";
    let navBarClassName = this.props.hidePrimaryNavBar
      ? "pos-fixed"
      : "bar--absolute";
    let navBarProps = this.props.hidePrimaryNavBar
      ? {}
      : {
          "data-scroll-class": "366px:pos-fixed",
        };

    let minProductReleaseAt = null;

    if (cart) {
      let productReleaseAts = cart.items.map((item) => item.release_at);
      productReleaseAts = productReleaseAts.filter(
        (releaseAt) => releaseAt != null
      );
      if (productReleaseAts.length > 0) {
        productReleaseAts.sort(function (a, b) {
          return new Date(a) - new Date(b);
        });
        minProductReleaseAt = productReleaseAts[0];
      }
    }

    return (
      <>

        {this._renderChat()}
        {this._renderPaymentWidget()}
        <CookieBanner website={website} editable={editable} />
        <div
          className={`s-header ${navBarParentClassName} ${
            data.website?.demo ? "site-wide-notice-on" : ""
          } ${
            data.website.hide_menu || this.props.hideMenu ? "header-off" : ""
          }`}
        >
          <div
            className={`h-bar bar bar--transparent p-0 ${navBarClassName}`}
            {...navBarProps}
          >
            {this._renderSiteWideNotice(editable)}
            <div className="nav-container">{this._renderMenu(editable)}</div>
          </div>
        </div>

        {minProductReleaseAt && (
          <div
            className="co-floating"
            onClick={() => {
              window.location = "/shop/checkout";
            }}
          >
            <div class="countdown">
              <svg
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M24.375 9.14062H20.3906V8.67188C20.3906 5.69531 17.9766 3.28125 15 3.28125C12.0234 3.28125 9.60938 5.69531 9.60938 8.67188V9.14062H5.625C5.10645 9.14062 4.6875 9.55957 4.6875 10.0781V25.7812C4.6875 26.2998 5.10645 26.7188 5.625 26.7188H24.375C24.8936 26.7188 25.3125 26.2998 25.3125 25.7812V10.0781C25.3125 9.55957 24.8936 9.14062 24.375 9.14062ZM18.2812 9.14062H11.7188V8.67188C11.7188 6.8584 13.1865 5.39062 15 5.39062C16.8135 5.39062 18.2812 6.8584 18.2812 8.67188V9.14062Z" />
              </svg>

              <div className="held fw-7">
                <div className="fs-12 title">Product Held: </div>
                <div className="timer">
                  <Countdown
                    date={minProductReleaseAt}
                    precision={3}
                    key={minProductReleaseAt}
                    renderer={({ hours, minutes, seconds }) => {
                      if (hours > 0) {
                        return (
                          <span>
                            {" " + zeroPad(hours)}h {zeroPad(minutes)}m{" "}
                            {zeroPad(seconds)}s
                          </span>
                        );
                      } else if (minutes > 0) {
                        return (
                          <span>
                            {" " + zeroPad(minutes)}m {zeroPad(seconds)}s
                          </span>
                        );
                      } else {
                        return <span>{" " + zeroPad(seconds)}s</span>;
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

const DEFAULT_PRIMARY_BUTTON = {
  settings: {
    primary_color: "#ffffff",
  },
  badge_settings: {
    primary_color: "#ffffff",
    secondary_color: null,
    tertiary_color: "#007ec1",
  },
  title: "Primary Button Text",
  url: "/",
  badge: null,
  open_in_new_tab: false,
};

const DEFAULT_SECONDARY_BUTTON = {
  settings: {
    secondary_color: "transparent",
  },
  badge_settings: {
    primary_color: "#ffffff",
    secondary_color: null,
    tertiary_color: "#007ec1",
  },
  title: "Secondary Button Text",
  url: "/",
  badge: null,
  open_in_new_tab: false,
};

const UNRESERVER_CART_ITEM_UUIDS = [];
